// centersData.js
import mekanissa1 from '../../../assets/images/icons/dmekanissa1.jpg';
import mekanissa2 from '../../../assets/images/icons/dmekanissa2.jpg';
import banner from '../../../assets/images/icons/mekanisa.jpg';
import adwabanner from '../../../assets/images/icons/City_of_Adwa.jpg';
import adwa from '../../../assets/images/icons/adwa.jpg';
import assosa from '../../../assets/images/icons/assossa.jpg';
import assosabanner from '../../../assets/images/icons/assosa.jpg';
import axum from '../../../assets/images/icons/axum.jpg';
import axumbanner from '../../../assets/images/icons/axumbanner.jfif';
import bahirdar from '../../../assets/images/icons/bahirdar.jpg';
import bahirdarBanner from '../../../assets/images/icons/bahirdarBanner.jpg';
import bishoftu from '../../../assets/images/icons/bishoftu.jpg';
import bishoftuBanner from '../../../assets/images/icons/bishoftubanner.jpg';
import debrebrihan from '../../../assets/images/icons/debrebrihan.jpg';
import debrebirhanBanner from '../../../assets/images/icons/debrebrihanbanner.jpg';

export const centersData = [
  {
    name: 'Main',
    bannerImage: banner,
    description: 'At Mekanissa Campus, we provide exceptional educational experiences.',
    deanMessage: 'Welcome to Mekanissa Campus! Our dedicated faculty are here to guide you.',
    deanImage: '../../../../assets/images/icons/meg1.jpg',
    deanName: 'Abrham G/Yohannes (PhD)',
    programs: [
    
		'Business Management', 'Agricultural Economics', 'Educational Planning and Management', 'Marketing Management', 'Economics', 'Rural Development & Agricultural Extension', 'Accounting and Finance'
    ],
    certifications: [
      { image: mekanissa1},
      { image: mekanissa2},
    ],
  },
  {
    name: 'Adwa',
    bannerImage: adwabanner,
    description: 'At Adwa Campus, we provide exceptional educational experiences.',
    deanMessage: 'Welcome to Adwa Campus! Our dedicated faculty are here to guide you.',
   
    programs: [
    
		'Business Management',  'Educational Planning and Management', 'Marketing Management', 'Economics',  'Accounting and Finance'
    ],
    certifications: [
      { image: adwa },
    
    ],
  },
	
  {
    name: 'Assosa',
    bannerImage: assosabanner,
    description: 'At Assosa Campus, we provide exceptional educational experiences.',
    deanMessage: 'Welcome to Assosa Campus! Our dedicated faculty are here to guide you.',
   
    programs: [
    
		'Business Management',  'Educational Planning and Management', 'Marketing Management', 'Economics',  'Accounting and Finance'
    ],
    certifications: [
      { image: assosa },
    
    ],
  },

   {
    name: 'Axum',
    bannerImage: axumbanner,
    description: 'At Axum Campus, we provide exceptional educational experiences.',
    deanMessage: 'Welcome to Axum Campus! Our dedicated faculty are here to guide you.',
   
    programs: [
    
		'Business Management',  'Educational Planning and Management', 'Marketing Management', 'Economics',  'Accounting and Finance'
    ],
    certifications: [
      { image: axum },
    
    ],
  },
	 {
    name: 'Bahirdar',
    bannerImage: bahirdarBanner,
    description: 'At Bahirdar Campus, we provide exceptional educational experiences.',
    deanMessage: 'Welcome to Bahirdar Campus! Our dedicated faculty are here to guide you.',
   
    programs: [
    
		'Business Management',  'Educational Planning and Management', 'Marketing Management', 'Economics',  'Accounting and Finance'
    ],
    certifications: [
      { image: bahirdar },
    
    ],
  },
	{
    name: 'Bishoftu',
    bannerImage: bishoftuBanner,
    description: 'At Bishoftu Campus, we provide exceptional educational experiences.',
    deanMessage: 'Welcome to Bishoftu Campus! Our dedicated faculty are here to guide you.',
   
    programs: [
    
		'Business Management',  'Educational Planning and Management', 'Marketing Management', 'Economics',  'Accounting and Finance'
    ],
    certifications: [
      { image: bishoftu},
    
    ],
  },
		{
    name: 'Debrebirhan',
    bannerImage: debrebirhanBanner,
    description: 'At Debrebirhan Campus, we provide exceptional educational experiences.',
    deanMessage: 'Welcome to Debrebirhan Campus! Our dedicated faculty are here to guide you.',
   
    programs: [
    
		'Business Management',  'Educational Planning and Management', 'Marketing Management', 'Economics',  'Accounting and Finance'
    ],
    certifications: [
      { image: debrebrihan},
    
    ],
  },
];