import React, { useState, useEffect, useRef } from 'react';
const VideoSection = () => {
  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="video-section"
      style={{ position: 'relative', textAlign: 'center' }}
      tabIndex={0} // Make the section focusable
      onFocus={() => {
        // Autoplay video when section gains focus
        if (isInView) {
          document.getElementById('youtube-embed').src += '?autoplay=1';
        }
      }}
    >
      {/* Video thumbnail area */}
      <div
        className="video-thumbnail"
        style={{
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          backgroundColor: 'black', // Optional: add a background color
        }}
      >
        <iframe
          id="youtube-embed"
          width="90%"
          height="90%"
          src="https://www.youtube.com/embed/Cmlv1RAeBn0"
          frameBorder="0"
          allowFullScreen
          style={{ display: isInView ? 'block' : 'none' }} // Show only when in view
        ></iframe>
        <div style={{ marginTop: '20px', fontSize: '38px', fontWeight: 'bold', color: 'whitesmoke' }}>
          Watch intro video <br /> about us
        </div>
      </div>
    </section>
  );
};

export default VideoSection;