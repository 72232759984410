import React, { useState } from 'react';
import campus from "../../../assets/images/resource/project-10.jpg";
import bish from '../../../assets/images/icons/bish.jpg';
import mekanisa2 from '../../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../../assets/images/icons/Abraham-Gebreyohanns.jpg';
import MekeleStudent from  '../../../assets/images/icons/mekelestudent.jpg';
import accreditationImage from '../../../assets/images/bish_acc1.jpg'; // Accreditation image 1
import accreditationImage1 from '../../../assets/images/bish_acc2.jpg'; // Accreditation image 2

const Bisheftu = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
  };

  return (
    <div>
      <div className="banner-slide">
        <img src={bish} alt="Banner 2" className="banner-image" />
        <div className="banner-content">
          <h1>Transforming Lives</h1>
          We believe in providing top quality workmanship and are <br />
          so confident in our level of service that we back it up <br /> with a good quality.
        </div>
      </div>

      <section className="projects-details">
        <div className="auto-container">
          <div className="sec-title style-two">
            <h2>Admas University Bisheftu Campus</h2>
          </div>
          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <h4>Message from Campus Dean</h4>
                <div className="text">
                          <p>
            Welcome to Admas University Bishoftu Campus @ <strong>Admas University Bishoftu Campus</strong>, we are dedicated to 
            <strong>academic excellence, innovative learning, and career-oriented education</strong>. 
            As one of Ethiopia’s leading private universities, we provide students with the 
            <strong>skills, knowledge, and practical experience</strong> needed to succeed in today’s dynamic world.
        </p>
        
        <p>
            Our campus is equipped with <strong>modern facilities, experienced faculty, and 
            industry-focused programs</strong> designed to bridge the gap between education and the job market. 
            We emphasize <em>critical thinking, problem-solving, and leadership development</em> to prepare our students 
            for real-world challenges.
        </p>
        
        <p>
            Whether you are starting your academic journey, enhancing your professional skills, 
            or seeking new career opportunities, <strong>Admas University Bishoftu Campus</strong> offers a 
            transformative learning experience. Join us and be part of an institution that is shaping the 
            future of higher education in Ethiopia and beyond.
        </p>

        <h4>📍 Admas University Bishoftu Campus – Empowering Future Leaders!</h4>                </div>
              </div>
              <div className="col-lg-6">
                <figure className="image pht">
                  <img src={Dean} alt="" />
                  <figcaption>Hiwot kisi (MSW)</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h2>Accredited Programs</h2>
                </div>
            

                <h5 className='programs'>Undergraduate Programs</h5>
                <ul className="sub-list">
                  <li>Accounting & Finance</li>
                  <li>Business Management</li>
                  <li>Marketing Management</li>
                  <li>Computer Science</li>
                 
                </ul>

                <div className='programs'>TVET Programs</div>
                <ul className="sub-list">
                  <li>Business & Finance Level 1 -4</li>
                  <li>Information Technology Level 1 - 4</li>
             
                </ul>

               
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={mekanisa2} alt="" /></div>
				  &nbsp;&nbsp;&nbsp;&nbsp;
				   {/* Accreditation Letter Display */}
                <div className="accreditation">
                  <h5>Certificate of Accreditation</h5>
                  {/* Icon Displays */}
                  <img 
                    src={accreditationImage} 
                    alt="Accreditation Letter 1" 
                    style={{ width: '150px', cursor: 'pointer', margin: '10px' }} 
                    onClick={() => openModal(accreditationImage)} 
                  />
                  <img 
                    src={accreditationImage1} 
                    alt="Accreditation Letter 2" 
                    style={{ width: '150px', cursor: 'pointer' }} 
                    onClick={() => openModal(accreditationImage1)} 
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-6">
                <h4>Student Life @ Bisheftu Campus</h4>
                <div className="text">
                  Reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur...
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={MekeleStudent} alt="" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Full Image */}
      {isModalOpen && (
        <div className="modal" style={modalStyle}>
          <span style={closeButtonStyle} onClick={closeModal}>&times;</span>
          <div style={modalContentStyle}>
            <img src={currentImage} alt="Accreditation Letter" style={imageStyle} />
          </div>
        </div>
      )}
    </div>
  )
}

// Simple modal styles
const modalStyle = {
  display: 'flex',
  position: 'fixed',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  maxWidth: '90%',
  maxHeight: '80%',
  overflowY: 'auto',
};

const imageStyle = {
  width: '100%',
  height: 'auto',
  maxHeight: '100%',
};

const closeButtonStyle = {
  color: 'white',
  position: 'absolute',
  top: '20px',
  right: '40px',
  fontSize: '40px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

export default Bisheftu;