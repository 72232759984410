import React from 'react'
import campus from "../../assets/images/resource/project-10.jpg" ;
import office from '../../assets/images/background/office.jpg';
import photo from "../../assets/images/icons/registrar.jpg";
import mekanisa2 from '../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../assets/images/icons/teklay.jpg'
import MekeleStudent from '../../assets/images/icons/mekelestudent.jpg'

const CentralRegistrar = () => {
  return (
    <div>
      <div className="banner-slide">
        <img src={office} alt="Banner" className="banner-image" />
        <div className="banner-content">
          <h1>Empowering Students</h1>
          We are committed to providing the highest quality of service to support our students' academic journey.
        </div>
      </div>

      <section className="projects-details">
        <h3 style={{ textAlign: 'center', marginBottom: '30px' }}>Central Registrar Office</h3>
        <div className="auto-container">
          <div className="text-block">
            <div className="row">
              <div className="col-lg-10">
                <h4>Welcome to the Office of the Central Registrar</h4>
                <div className="text">
                  <p>The Central Registrar's Office at Admas University is dedicated to managing student records and ensuring a seamless registration process. Our mission is to support students in achieving their academic goals through efficient and effective services.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h4>Meet the Registrar</h4>
                </div>
                <div>
                  <p>Mr. Temesgen Habtu has extensive experience in higher education administration and is committed to enhancing student services and academic integrity at Admas University.</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="image"><img src={photo} alt="Registrar" /></div>
                <p className='designation'>Temesgen Habtu
</p>
                <p style={{ textAlign: 'center' }}>Director, Central Registrar & Programing Office</p>
              </div>
            </div>
          </div>

          <hr />
          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-12">
                <h4>Our Commitment to Students</h4>
                <div className="text">
                  <p>The Central Registrar's Office is dedicated to ensuring that every student has access to the resources and support they need to succeed academically.</p>
                  <ul>
                    <h4>Key Responsibilities</h4>
                    <li><strong>Registration Process:</strong> Facilitating the course registration process for students each semester.</li>
                    <li><strong>Record Keeping:</strong> Maintaining accurate and secure student records in compliance with institutional policies.</li>
                    <li><strong>Academic Advising:</strong> Providing guidance to students regarding their academic programs and requirements.</li>
                    <li><strong>Transcripts and Certifications:</strong> Issuing official transcripts and certificates upon request.</li>
                    <li><strong>Policy Implementation:</strong> Enforcing academic policies and procedures to uphold academic integrity.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-10">
                <h4>Innovative Initiatives</h4>
                <div className="text">
                  <p>We are excited to introduce new initiatives aimed at improving the registration process and enhancing student services.</p>
                  <ol>
                    <li><strong>Online Registration:</strong> Streamlining the registration process with an easy-to-use online platform.</li>
                    <li><strong>Enhanced Communication:</strong> Implementing new communication strategies to keep students informed about important deadlines and requirements.</li>
                    <li><strong>Workshops and Training:</strong> Offering workshops to help students understand registration policies and procedures.</li>
                  </ol>
                </div>
                <h4>Join Us in Supporting Student Success</h4>
                <p>At Admas University, we believe that the Registrar's Office plays a crucial role in supporting students throughout their academic journey. We invite you to reach out with any questions or concerns.</p>
              </div>
            </div>
          </div>

          <hr />
          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-10">
                <h4>Contact Us</h4>
                <div className="text">
                  For more information or inquiries, please reach out to the Central Registrar's Office:
                  <br />
                  Email: [registrar@admasuniversity.edu.et]
                  <br />
                  Phone: [+251 123 456 789]
                  <br />
                  Office Location: Dembel, 503, Admas University]
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CentralRegistrar;