import { useState, useEffect } from 'react';
import employeeService from "../../../src/services/employee.service";
import { useAuth } from '../../../src/context/AuthContext';
import PresidentMessage from './PresidentMessage';
import ScrollToTopButton from '../pages/ScrollToTopButton';
import CTASection from '../pages/CTASection'
const api_url = process.env.REACT_APP_API_URL;

const TeamSection = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { employee } = useAuth();
  const token = employee ? employee.employee_token : null;

  const handleImageClick = (image) => {
    setSelectedImage(`${api_url}/${image}`);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const response = await employeeService.getAllEmployees(token);
        if (!response.ok) {
          throw new Error('Failed to fetch employees');
        }
        const data = await response.json();
        setEmployees(data.data); // Assumes data returns an array of employees
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!employees || employees.length === 0) {
    return <div>No employees found.</div>;
  }

  return (
    <div className="team-section">
      <div className="auto-container">
        <div className="sec-title">
          <h2>Meet our Leaders</h2>
        </div>
        <div className="text">
          The dedicated team at Admas University is committed to providing an exceptional educational experience for our students. Our faculty members are renowned experts in their respective fields, bringing a wealth of both academic and real-world experience to the classroom.
        </div>
        <PresidentMessage />
        <div className="row">
          {employees
            .filter(emp => emp.company_role_name === 'Manager') // Filter for managers
            .map(emp => (
              <div className="col-lg-4 col-md-4 col-sm-12 team-block-one" key={emp.employee_id}>
                <div className="inner-box">
                  {emp.employee_image && (
                    <img
                      src={`${api_url}/${emp.employee_image}`}
                      alt={`${emp.employee_first_name} ${emp.employee_last_name}`}
                      style={{ width: '100%', height: '500px', objectFit: 'cover', cursor: 'pointer' }}
                      onClick={() => handleImageClick(emp.employee_image)}
                    />
                  )}
                  <h3 className="designation">{emp.employee_first_name} {emp.employee_last_name} <span>(</span> {emp.specialization}<span>)</span></h3>
                  <div >  {emp.position}</div>
                  {console.log(emp)}
                </div>
              </div>
            ))}
        </div>
      </div>
      {showModal && (
        <div className="modal" style={{ display: 'block', position: 'fixed', zIndex: '1', left: '0', top: '0', width: '100%', height: '100%', overflow: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
          <div className="modal-content" style={{ backgroundColor: '#fefefe', margin: '15% auto', padding: '20px', border: '1px solid #888', maxWidth: '80%' }}>
            <span className="close" onClick={closeModal} style={{ color: '#aaa', float: 'right', fontSize: '28px', fontWeight: 'bold', cursor: 'pointer' }}>&times;</span>
            <img src={selectedImage} alt="Expanded" style={{ width: '100%', height: 'auto' }} />
          </div>
        </div>
      )}
      <CTASection/>
      <ScrollToTopButton />
    </div>
  );
};

export default TeamSection;