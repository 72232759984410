import React from 'react';
import campus from "../../../assets/images/resource/project-10.jpg";
import Mekelebanner from '../../../assets/images/icons/mekele.png';
import Mekele2 from '../../../assets/images/icons/mekele2.jpg';
import Dean from '../../../assets/images/icons/Abraham-Gebreyohanns.jpg';
import MekeleStudent from '../../../assets/images/icons/mekelestudent.jpg';

const Misrak = () => {
  const iconStyle = {
    marginRight: '20px',
    color: '#1ab5f1',
    fontWeight: 'bolder',
    fontSize: '1.5em',
  };

  return (
    <div>
      <div className="banner-slide">
        <img src={Mekelebanner} alt="Banner" className="banner-image" />
        <div className="banner-content">
          <h1>Transforming Lives</h1>
          <p>
            We believe in providing top quality workmanship and are <br />
            so confident in our level of service that we back it up <br />
            with a good quality.
          </p>
        </div>
      </div>

      <section className="projects-details">
        <div className="auto-container">
          <div className="sec-title style-two">
            <h2>Admas University Misrak Campus</h2>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <h4>Message from Campus Dean</h4>
                <div className="text">
                  <p>
                    Welcome to Admas University Misrak Campus! <strong>Admas University Misrak TVET Campus</strong> is dedicated to providing 
                    <strong>high-quality technical and vocational education</strong> that empowers students with 
                    practical skills and hands-on experience. As a leading TVET (Technical and Vocational Education and Training) 
                    institution, we focus on equipping students with the competencies and certifications needed 
                    to succeed in today’s job market.
                  </p>
                  <p>
                    Our programs are designed to meet industry demands, ensuring that graduates are 
                    job-ready and highly skilled. With a strong emphasis on practical learning, entrepreneurship, 
                    and professional development, we help students build careers in various technical fields.
                  </p>
                  <p>
                    Whether you're looking to gain a new skill, enhance your qualifications, or start your own business, 
                    <strong>Admas University Misrak TVET Campus</strong> provides a structured, hands-on learning experience 
                    to prepare you for success. Join us today and take the next step toward a brighter future!
                  </p>
                  <h3>📍 Admas University Misrak TVET Campus – Skills for Success!</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <figure className="image pht">
                  <img src={Dean} alt="Dean" />
                  <figcaption>Molla Tsegay (PhD)</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h2>Accredited Programs</h2>
                </div>
                <h5 className='programs'>TVET Programs</h5>
                <ul className="list">
                  {['Web Development & Database Administration Level 1-4', 
                    'Hardware & Networking Servicing Level 1-4', 
                    'Human Resource Management Level 2-4', 
                    'Accounting & Finance Level 2-4'].map((program, index) => (
                      <li key={index}>
                        <div className="icon-box">
                          <span className="flaticon-book" style={iconStyle}></span>
                          {program}
                        </div>
                      </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={Mekele2} alt="Mekele Campus" /></div>
              </div>
            </div>
          </div>

          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-6">
                <h4>Student Life @ Misrak Campus</h4>
                <div className="text">
                  Reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt 
                  mollit anim id est laborum.
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={MekeleStudent} alt="Students at Misrak Campus" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Misrak;