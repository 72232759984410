import React from 'react';
import campus from "../../../assets/images/resource/project-10.jpg";
import Mekelebanner from '../../../assets/images/icons/mekele.png';
import Mekele2 from '../../../assets/images/icons/mekele2.jpg';
import Dean from '../../../assets/images/icons/meskelDean.jpg';
import MekeleStudent from '../../../assets/images/icons/mekelestudent.jpg';

const Meskel = () => {
  const iconStyle = {
    marginRight: '20px',
    color: '#1ab5f1',
    fontWeight: 'bolder',
    fontSize: '1.5em',
  };

  return (
    <div>
      <div className="banner-slide">
        <img src={Mekelebanner} alt="Banner 2" className="banner-image" />
        <div className="banner-content">
          <h1>Transforming Lives</h1>
          <p>
            We believe in providing top quality workmanship and are <br />
            so confident in our level of service that we back it up <br /> 
            with a good quality.
          </p>
        </div>
      </div>

      <section className="projects-details">
        <div className="auto-container">
          <div className="sec-title style-two">
            <h2>Admas University Meskel Campus</h2>
          </div>
          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <h4>Message from Campus Dean</h4>
                <div className="text">
                  <p>
                    Welcome to Admas University Meskel Campus! We are dedicated to academic excellence, 
                    innovative learning, and career-oriented education. As one of Ethiopia’s leading private 
                    universities, we provide students with the skills, knowledge, and practical experience 
                    needed to succeed in today’s dynamic world.
                  </p>
                  <p>
                    Our campus is equipped with modern facilities, experienced faculty, and industry-focused 
                    programs designed to bridge the gap between education and the job market. We emphasize 
                    critical thinking, problem-solving, and leadership development to prepare our students for 
                    real-world challenges.
                  </p>
                  <p>
                    Whether you are starting your academic journey, enhancing your professional skills, or 
                    seeking new career opportunities, Admas University Meskel Campus offers a transformative 
                    learning experience. Join us and be part of an institution that is shaping the future of 
                    higher education in Ethiopia and beyond.
                  </p>
                  <p><strong>📍 Admas University Meskel Campus – Empowering Future Leaders!</strong></p>
                </div>
              </div>
              <div className="col-lg-6">
                <figure className="image pht">
                  <img src={Dean} alt="Dean" />
                  <figcaption>Abubeker Abdu (MA)</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h2>Accredited Programs</h2>
                </div>
                <h5 className='programs'>Undergraduate Programs</h5>
                <ul className="sub-list">
                  <li>
                    <div className="icon-box">
                      <span className="flaticon-book" style={iconStyle}></span>
                      Accounting & Finance
                    </div>
                  </li>
                  <li>
                    <div className="icon-box">
                      <span className="flaticon-book" style={iconStyle}></span>
                      Business Management
                    </div>
                  </li>
                  <li>
                    <div className="icon-box">
                      <span className="flaticon-book" style={iconStyle}></span>
                      Marketing Management
                    </div>
                  </li>
                  <li>
                    <div className="icon-box">
                      <span className="flaticon-book" style={iconStyle}></span>
                      Computer Science
                    </div>
                  </li>
                </ul>

                <h5 className='programs'>TVET Programs</h5>
                <ul className="list">
                  {['Web Development & Database Administration Level 1-4', 
                    'Hardware & Networking Servicing Level 1-4', 
                    'Human Resource Management Level 2-4', 
                    'Accounting & Finance Level 2-4'].map((program, index) => (
                      <li key={index}>
                        <div className="icon-box">
                          <span className="flaticon-book" style={iconStyle}></span>
                          {program}
                        </div>
                      </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={Mekele2} alt="Mekele Campus" /></div>
              </div>
            </div>
          </div>

          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-6">
                <h4>Student Life @ Meskel Campus</h4>
                <div className="text">
                  Reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt 
                  mollit anim id est laborum.
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={MekeleStudent} alt="Students at Meskel Campus" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Meskel;