import React, { useState, useEffect } from 'react';
import './news.css';
import getAllNews from '../../services/news.service';
import { useNavigate } from 'react-router-dom';

const LatestNewsTransition = () => {
  const navigate = useNavigate();
  const [newsItems, setNewsItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getAllNews.getAllNews();
        console.log(data);
        setNewsItems(data.data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % newsItems.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [newsItems]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  function handleNewsClick(newsItem) {
    navigate('/news', { state: newsItem });
    console.log(newsItem);
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % newsItems.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex - 1 + newsItems.length) % newsItems.length
    );
  };

  return (
    <div className='shnews'>
      {newsItems.length > 0 && (
        <div key={newsItems[currentIndex].news_event_id} className='shortnews'>
          <h2 className='latest-news-label'> ANNOUNCEMENT</h2>
        
             <button onClick={handlePrevious} disabled={newsItems.length <= 1}style={{alignItems:'left',   backgroundColor:'rgba(189, 245, 246, 0.26)',color:'blue'}}>
              &lt;
            </button>
            &nbsp;&nbsp;
            <a
            className=''
            onClick={() => handleNewsClick(newsItems[currentIndex])}
          >
            {newsItems[currentIndex].title}{' '}
            <span className='news-date'>
              {formatDate(newsItems[currentIndex].created_at)}
            </span>
            </a>
            &nbsp;&nbsp;
            <button onClick={handleNext} disabled={newsItems.length <= 1} style={{alignItems:'right',  backgroundColor:'rgba(189, 245, 246, 0.26)',color:'blue'}}>
            &gt;
            </button>
          
          
        </div>
      )}
    </div>
  );
};

export default LatestNewsTransition;