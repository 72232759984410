import React from 'react';
import campus from "../../assets/images/resource/project-10.jpg";
import office from '../../assets/images/icons/ICToffice.jpg';
import Training from './Training.jsx';
import photo from "../../assets/images/president.png";
import mekanisa2 from '../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../assets/images/icons/teklay.jpg';
import MekeleStudent from  '../../assets/images/icons/mekelestudent.jpg';

const CentralICT = () => {
  return (
    <div>
      <div className="banner-slide">
        <img src={office} alt="Banner" className="banner-image" />
        <div className="banner-content">
          <h1>Transforming Lives</h1>
          <p>
            We believe in providing top quality workmanship and are <br />
            so confident in our level of service that we back it up <br />
            with a good quality.
          </p>
        </div>
      </div>

      <section className="projects-details">
        <h3 style={{ textAlign: 'center', marginBottom: '30px' }}>Office of Central ICT</h3>
        <div className="auto-container">
          <div className="text-block">
            <div className="row">
              <div className="col-lg-10">
                <div className="text">
                  {/* Additional content can be added here if needed */}
                </div>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h4>Meet the ICT Vice President</h4>
                </div>
                <div>
                  <p>
                    Short biography highlighting the President’s background, academic qualifications, and leadership experience. 
                    Mention any significant achievements and contributions to the university and community.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="image">
                  <img src={Dean} alt="Dean Teklay Hagos" />
                </div>
                <p className="designation">Teklay Hagos</p>
              </div>
            </div>
          </div>

          <Training />

          <hr />
          
          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-10">
                <h4>Contact Us</h4>
                <div className="text">
                  For more information or inquiries, please reach out to the Office of Central ICT:
                  <br />
                  Email: <a href="mailto:teklayh@admasuniversity.edu.et">teklayh@admasuniversity.edu.et</a>
                  <br />
                  Phone: +251 123 456 789
                  <br />
                  Office Location: Olympia, 604, Admas University
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CentralICT;