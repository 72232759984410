import { useState, useEffect } from 'react';
import news from '../../services/news.service';
import DOMPurify from 'dompurify';
import ScrollToTopButton from '../pages/ScrollToTopButton';
const api_url = process.env.REACT_APP_API_URL;

const Events = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(`${api_url}/${image}`);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await news.getAllNews();
        setAnnouncements(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!announcements || announcements.length === 0) {
    return <div>No announcements found.</div>;
  }

  return (
    <div className='events'>
     
    <div className="blog-section" style={{ minHeight: '100vh', marginTop: '100px' }}>
      <div className="auto-container">
      <h2 style={{textAlign:'center',marginBottom:'20px'}}>Admas University News & Events</h2>
        <div className="row">
          
          {announcements.map((announcement, index) => (
            <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
              <div className="inner-box">
                <h3 style={{textAlign:'center',margin:'20px auto '}}>{announcement.title}</h3>
                {announcement.image && (
                  <img
                    src={`${api_url}/${announcement.image}`}
                    alt={announcement.title}
                    style={{ width: '100%', height: '200px', objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() => handleImageClick(announcement.image)}
                  />
                )}
                <div className="lower-content">
             
                <div className="lower-content">
                  <div className="date">{new Date(announcement.created_at).toLocaleDateString()} <span style={{fontSize:'1rem'}}> By : {announcement.author}</span></div>
                  <h5></h5>
                  <div>
                    {announcement.content.split(' ').slice(0, 30).join(' ')}
                    {announcement.content.split(' ').length > 30 && (
                      <button
                        style={{ marginLeft: '10px', color: 'white',backgroundColor: '#31a8db'}}
                        onClick={() => {
                          const contentDiv = document.getElementById(`content-${index}`);
                          contentDiv.style.display = 'block';
                          const readMoreBtn = document.getElementById(`read-more-${index}`);
                          readMoreBtn.style.display = 'none';
                        }}
                        id={`read-more-${index}`}
                      >
                        Read More >>
                      </button>
                    )}
                  </div>
                  <div id={`content-${index}`} style={{ display: 'none' }}>
                    {announcement.content}
                  </div>
                </div>
                </div>
              </div>
            </div>
            
          ))}
        </div>
      </div>
      {showModal && (
        <div className="modal" style={{ display: 'block', position: 'fixed', zIndex: '1', left: '0', top: '0', width: '100%', height: '100%', overflow: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
          <div className="modal-content" style={{ backgroundColor: '#fefefe', margin: '15% auto', padding: '20px', border: '1px solid #888', maxWidth: '80%' }}>
            <span className="close" onClick={closeModal} style={{ color: '#aaa', float: 'right', fontSize: '28px', fontWeight: 'bold', cursor: 'pointer' }}>&times;</span>
            <img src={selectedImage} alt="Expanded" style={{ width: '100%', height: 'auto' }} />
          </div>
        </div>
      )}
      <ScrollToTopButton />
    </div>
    </div>
  );
};

export default Events;