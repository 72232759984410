import React, { useState, useEffect, useRef } from 'react';
import campus from "../../../assets/images/resource/project-10.jpg";
import image1 from "../../../assets/images/background/ad.jpg";
import olympiya from '../../../assets/images/icons/olympiya.jpg';
import Dean from '../../../assets/images/icons/teklay.jpg';
import MekeleStudent from '../../../assets/images/icons/mekelestudent.jpg';
import '../../../assets/css/style.css';

const MainCampus = () => {
  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div>
      <div className="banner-slide">
        <img src={image1} alt="Banner 2" className="banner-image" />
        <div className="banner-content">
          <h2>Transforming Lives</h2>
          We believe in providing top quality workmanship and are <br />
          so confident in our level of service that we back it up <br /> with a good quality.
        </div>
      </div>

      <section className="projects-details">
        <div className="auto-container">
          <div className="sec-title style-two">
            <h1>Admas University  Olympia Campus</h1>
          </div>
          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <h4>Message from the Dean – Olympia Campus</h4>
                <div className="text">
                  <p>Welcome to Admas University Olympia Campus – Excellence in Education, Success in Your Hands!</p>
                  <p>At Olympia Campus, we take pride in being a premier private university in a prime, 
                    accessible location, offering world-class education tailored for career success.
                    Our commitment to academic excellence, innovation, and practical learning sets us apart as a leading institution.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <figure className="image pht">
                  <img src="" alt="Dean" />
                  <figcaption>Tesfahun Kumlachew (MA)</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h3>Accredited Programs</h3>
                </div>
                <div className="col-md-6">
                  <h5 className='programs'>Undergraduate Programs</h5>
                  <ul className="sub-list">
                    <li>
                      <div className="icon-box">
                        <span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>
                        Accounting & Finance
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>
                        Business Management
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>
                        Marketing Management
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>
                        Computer Science
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image">
                  <img src={olympiya} alt="olympia campus" />
                </div>
              </div>
            </div>
          </div>

          <section
            ref={sectionRef}
            className="video-section"
            style={{ position: 'relative', textAlign: 'center' }}
            tabIndex={0} // Make the section focusable
            onFocus={() => {
              // Autoplay video when section gains focus
              if (isInView) {
                document.getElementById('youtube-embed').src += '?autoplay=1';
              }
            }}
          >
            <div
              className="video-thumbnail"
              style={{
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: 'black', // Optional: add a background color
              }}
            >
              <iframe
                id="youtube-embed"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Cmlv1RAeBn0"
                frameBorder="0"
                allowFullScreen
                style={{ display: isInView ? 'block' : 'none' }} // Show only when in view
              ></iframe>
              <div style={{ marginTop: '20px', fontSize: '28px', fontWeight: 'bold', color: 'whitesmoke' }}>
                Watch intro video <br /> about us
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}

export default MainCampus;