import React, { useState } from 'react';
import campus from "../../../assets/images/resource/project-10.jpg";
import mekanisa from '../../../assets/images/icons/mekanisa.jpg';
import mekanisa2 from '../../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../../assets/images/icons/mekanisaDean.jpg';
import MekeleStudent from  '../../../assets/images/icons/mekelestudent.jpg';
import accreditationImage from '../../../assets/images/mekanisa_acc1.jpg'; 
import accreditationImage1 from '../../../assets/images/icons/mekanisaUG.png'; 
import accreditationImage2 from '../../../assets/images/icons/mekanisapm.jpg'; 
const Mekanisa = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
  };

  return (
    <div>
      <div className="banner-slide">
        <img src={mekanisa} alt="Banner 2" className="banner-image" />
        <div className="banner-content">
          <h1>Empowering Students at Our Private University</h1>
          <div>We offer high-quality education and dedicated support, ensuring every student has a transformative experience.</div>
          <div>Join us to achieve your academic and personal goals in a nurturing environment.</div>
        </div>
      </div>

      <section className="projects-details">
        <div className="auto-container">
          <div className="sec-title style-two">
            <h2>Admas University Mekanisa Campus</h2>
          </div>
          <div className="text-block">
            <div className="row">
              <div className="col-lg-8">
                <h4>Message from Campus Dean</h4>
                <div className="text">
                 
      
        <p>
            Welcome to Admas University Mekanissa Campus @ <strong>Admas University Mekanissa Campus</strong>, we are dedicated to 
            <strong>academic excellence, innovation, and student success</strong>. 
            As one of Ethiopia’s leading private universities, we take pride in offering 
            <strong>industry-relevant programs, modern learning facilities, and a dynamic educational environment</strong> 
            that prepares students for the future.
        </p>
        
        <p>
            Our commitment to <strong>quality education</strong> extends beyond the classroom, 
            ensuring that students gain <em>practical skills, critical thinking abilities, and leadership qualities</em> 
            to excel in today’s competitive world. With a strong faculty, cutting-edge resources, and a 
            student-centered approach, we continue to set the benchmark for private higher education in 
            Ethiopia and East Africa.
        </p>
        
        <p>
            Join us in shaping the future! Whether you are a prospective student, researcher, or industry partner, 
            <strong>Admas University Mekanissa Campus</strong> is the place where <em>opportunity meets excellence</em>.
        </p>

        <h4>📍 Admas University Mekanissa Campus – Your Future Starts Here!</h4>
    
                </div>
              </div>
              <div className="col-lg-3">
                <figure className="image pht">
                  <img src={Dean} alt="" />
                  <figcaption>Girmay Abadi (MBA)</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h2>Accredited Programs</h2>
                </div>
                
                <h5 className='programs'>Postgraduate Programs</h5>
                <ul className="list">
                  <ul className="sub-list">
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Accounting & Finance</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Master of Business Administration</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Project Management</div></li>
                  </ul>
                </ul>

                <h5 className='programs'>Undergraduate Programs</h5>
                <ul className="list">
                  <ul className="sub-list">
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Accounting & Finance</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Business Management</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Marketing Management</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Computer Science</div></li>
                  </ul>
                </ul>

                <div className='programs'>TVET</div>
                <ul className="list">
                  <ul className="sub-list">
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Web Development & Database Administration Level 1-4</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Hardware & Networking Servicing Level 1-4</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Secretarial Science & Office Administration Level 2-4</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Marketing & Sales Management Level 2-4</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{ marginRight: '20px', color: '#1ab5f1', fontWeight: 'bolder', fontSize: '1.5em' }}></span>Accounting & Finance Level 2-4</div></li>
                  </ul>
                </ul>

             
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={mekanisa2} alt=""/></div>
				  &nbsp;&nbsp;&nbsp;&nbsp;
				     {/* Accreditation Letter Display */}
                <div className="accreditation">
                  <h5>Certificate of Accreditation</h5>
                  {/* Icon Displays */}
                  <img 
                    src={accreditationImage} 
                    alt="Accreditation Letter 1" 
                    style={{ width: '150px', cursor: 'pointer', marginRight: '10px' }} 
                    onClick={() => openModal(accreditationImage)} 
                  />
                  <img 
                    src={accreditationImage2} 
                    alt="Accreditation Letter 3" 
                    style={{ width: '150px', cursor: 'pointer' }} 
                    onClick={() => openModal(accreditationImage2)} 
                  />
					
					     <img 
                    src={accreditationImage1} 
                    alt="Accreditation Letter 2" 
                    style={{ width: '150px', cursor: 'pointer' }} 
                    onClick={() => openModal(accreditationImage1)} 
                  />
                </div>

              </div>
            </div>
          </div>

          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-6">
                <h4>Student Life @ Mekanisa Campus</h4>
<div className="text">
  Mekanisa Campus of Admas University stands out as the premier destination for learning, providing an enriching environment for both postgraduate and undergraduate students. With state-of-the-art facilities, dedicated faculty, and a vibrant student community, it fosters academic excellence and personal growth. 
  Here, students are encouraged to explore their passions, engage in collaborative projects, and develop the skills necessary for their future careers. The campus not only supports rigorous academic pursuits but also promotes a balanced student life, making it the ideal place to thrive both intellectually and socially.
</div>
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={MekeleStudent} alt=""/></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Full Image */}
      {isModalOpen && (
        <div className="modal" style={modalStyle}>
          <span style={closeButtonStyle} onClick={closeModal}>&times;</span>
          <div style={modalContentStyle}>
            <img src={currentImage} alt="Accreditation Letter" style={imageStyle} />
          </div>
        </div>
      )}
    </div>
  )
}

// Simple modal styles
const modalStyle = {
  display: 'flex',
  position: 'fixed',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  maxWidth: '90%',
  maxHeight: '80%',
  overflowY: 'auto',
};

const imageStyle = {
  width: '100%',
  height: 'auto',
  maxHeight: '100%',
};

const closeButtonStyle = {
  color: 'white',
  position: 'absolute',
  top: '20px',
  right: '40px',
  fontSize: '40px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

export default Mekanisa;