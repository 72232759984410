// MekanissaPage.js
import React from 'react';
import DistanceCenter from './DistanceCenter';
import { centersData } from './centersData';

const MekanissaPage = () => {
  const center = centersData[0]; // Access the Mekanissa data
  return <DistanceCenter center={center} />;
};

export default MekanissaPage;