import React, { useState } from 'react';

const DistanceCenter = ({ center }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
  };

  const iconStyle = {
    marginRight: '10px',
    color: '#1ab5f1',
    fontWeight: 'bolder',
    fontSize: '1.5em',
  };

  return (
    <div>
      <div className="banner-slide" style={{ height: '90vh', position: 'relative' }}>
	  console.log(center)
        <img src={center.bannerImage} alt={`Transforming Lives at ${center.name}`} className="banner-image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div className="banner-content" style={{ color: 'white', position: 'absolute', bottom: '20px', left: '20px' }}>
          <h1>Transforming Lives at {center.name}</h1>
          <p>{center.description}</p>
        </div>
      </div>

      <section>
        <div className="auto-container">
          <div className="sec-title style-two">
            <h2 style={{ marginTop: '30px' }}>Admas University College of Distance Education {center.name} Campus</h2>
          </div>

          {/* Accredited Programs Section */}
          <div className="card" style={{ marginTop: '70px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <h2>Accredited Programs</h2>
              <ul className="list">
                {center.programs.map((program, index) => (
                  <li key={index}>
                    <div className="icon-box">
                      <span className="flaticon-book" style={iconStyle}></span>
                      {program}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div style={{ flex: '0 0 150px', marginLeft: '20px' }}>
              <h5>Certificate of Accreditation</h5>
              {center.certifications.map((cert, index) => (
                <img 
                  key={index} 
                  src={cert.image} 
                  alt={`Accreditation ${index + 1}`} 
                  style={{ width: '150px', cursor: 'pointer', marginRight: '10px' }} 
                  onClick={() => openModal(cert.image)} 
                />
              ))}
            </div>
          </div>

          {isModalOpen && (
            <div className="modal" style={{ display: 'block', position: 'fixed', zIndex: '1', left: '0', top: '0', width: '100%', height: '100%', overflow: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
              <div className="modal-content" style={{ backgroundColor: '#fefefe', margin: '15% auto', padding: '20px', border: '1px solid #888', maxWidth: '80%' }}>
                <span className="close" onClick={closeModal} style={{ color: '#aaa', float: 'right', fontSize: '28px', fontWeight: 'bold', cursor: 'pointer' }}>&times;</span>
                <img src={currentImage} alt="Expanded" style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default DistanceCenter;