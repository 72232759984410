import React, { useState } from 'react';
import campus from "../../../assets/images/resource/project-10.jpg";
import kality from '../../../assets/images/icons/kality.jpg';
import mekanisa2 from '../../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../../assets/images/icons/kalityDean.jpg';
import MekeleStudent from  '../../../assets/images/icons/mekelestudent.jpg';
import accreditationImage from '../../../assets/images/kal_acc.jpg'; // Accreditation image 1
import accreditationImage1 from '../../../assets/images/kal_acc.jpg'; // Accreditation image 2

const Kality = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
  };

  return (
    <div>
      <div className="banner-slide">
        <img src={kality} alt="Banner 2" className="banner-image" />
        <div className="banner-content">
          <h1>Transforming Lives</h1>
          We believe in providing top quality workmanship and are <br />
          so confident in our level of service that we back it up <br /> with a good quality.
        </div>
      </div>

      <section className="projects-details">
        <div className="auto-container">
          <div className="sec-title style-two">
            <h2>Admas University Kality Campus</h2>
          </div>
          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <h4>Message from Campus Dean</h4>
                <div className="text">
                   
        <p>
            Welcome to Admas University Kality Campus @ <strong>Admas University Kality Campus</strong>, we are committed to 
            <strong>academic excellence, practical learning, and career-oriented education</strong>. 
            As a leading private university in Ethiopia, we provide students with the 
            <strong>knowledge, skills, and hands-on experience</strong> needed to thrive in today’s fast-changing world.
        </p>
        
        <p>
            Our programs are designed to meet industry demands, offering a blend of 
            <strong>theoretical knowledge and real-world applications</strong>. 
            With experienced faculty, state-of-the-art facilities, and a strong focus on student success, 
            we ensure that our graduates are well-equipped for the job market.
        </p>
        
        <p>
            Whether you're looking to advance your education, gain professional skills, 
            or explore new career opportunities, <strong>Admas University Kality Campus</strong> is the 
            perfect place to start. Join us and be part of an institution that is shaping the 
            future of education in Ethiopia and beyond.
        </p>

        <h4>📍 Admas University Kality Campus – Empowering Your Future!</h4>
                </div>
              </div>
              <div className="col-lg-6">
                <figure className="image pht">
                  <img src={Dean} alt="" />
                  <figcaption>Natnael Siyum (MA)</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h2>Accredited Programs</h2>
                </div>
                
                

                <h5 className='programs'>Undergraduate Programs</h5>
                <ul className="list">
                  <ul className="sub-list">
                    <li><div className="icon-box"><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Accounting & Finance</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Business Management</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Marketing Management</div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Computer Science</div></li>
                  </ul>
                </ul>

                <div className='programs'>TVET</div>
                <ul className="list">
                  <ul className="sub-list">
                    <li><div className="icon-box"><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Business & Finance Level 1 - 4 </div></li>
                    <li><div className="icon-box"><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Information Technology Level 1 - 4</div></li>
                 
                  </ul>
                </ul>

               

              </div>
              <div className="col-lg-6">
                <div className="image"><img src={mekanisa2} alt=""/></div>
				  &nbsp;&nbsp;&nbsp;&nbsp;
				   {/* Accreditation Letter Display */}
                <div className="accreditation">
                  <h5>Certificate of Accreditation</h5>
                  {/* Icon Displays */}
                  <img 
                    src={accreditationImage} 
                    alt="Accreditation Letter 1" 
                    style={{ width: '150px', cursor: 'pointer', marginRight: '10px' }} 
                    onClick={() => openModal(accreditationImage)} 
                  />
                  <img 
                    src={accreditationImage1} 
                    alt="Accreditation Letter 2" 
                    style={{ width: '150px', cursor: 'pointer' }} 
                    onClick={() => openModal(accreditationImage1)} 
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-6">
                <h4>Student Life @ Kality Campus</h4>
                <div className="text">
                  Reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco proident, sunt in culpa qui officia deserunt mollit anim id est laborum.  
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={MekeleStudent} alt=""/></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Full Image */}
      {isModalOpen && (
        <div className="modal" style={modalStyle}>
          <span style={closeButtonStyle} onClick={closeModal}>&times;</span>
          <div style={modalContentStyle}>
            <img src={currentImage} alt="Accreditation Letter" style={imageStyle} />
          </div>
        </div>
      )}
    </div>
  )
}

// Simple modal styles
const modalStyle = {
  display: 'flex',
  position: 'fixed',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  maxWidth: '90%',
  maxHeight: '80%',
  overflowY: 'auto',
};

const imageStyle = {
  width: '100%',
  height: 'auto',
  maxHeight: '100%',
};

const closeButtonStyle = {
  color: 'white',
  position: 'absolute',
  top: '20px',
  right: '40px',
  fontSize: '40px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

export default Kality;