import React from 'react';
import ServiceBlock from './ServiceBlock';
import mainicon from '../../assets/images/icons/mainicon.png';
import mekele from '../../assets/images/icons/mekele.jpg';
import mekanissa from '../../assets/images/icons/mekanisa.jpg';
import Megenagna from '../../assets/images/icons/meg1.jpg';
import Bish from '../../assets/images/icons/bish.jpg';
import Kality from '../../assets/images/icons/kality.jpg';
import distance from '../../assets/images/icons/distance.jpg';
import meskel from '../../assets/images/icons/meskel.jpg';
import './ServiceSection.css'
const ServicesSection = (props) => {
  return (
    <section className="services-section">
      <div className="auto-container">
        <div className="sec-title style-two" style={{ textAlign: 'center' }}>
          <h2>Admas University Campuses</h2>
          <div className="text">
            With the objective of expanding its quality services, the University opened ten (10) colleges/campuses/faculties so far. Eight of these campuses are found in Ethiopia among which six are in Addis Ababa while the rest two are in Bishoftu and Mekelle towns. The other two campuses are located out of Ethiopia in the Capitals of Somaliland and Puntland (i.e. Hargeissa and Garowe, respectively). Apart from the regular mode, the University also has a Distance Education College with more than 50 (fifty) coordination offices throughout the country.
          </div>
        </div>
        <div className="row">
          <ServiceBlock
            title="Admas University Main Campus"
            serviceName=" OLYMPIA MAIN CAMPUS"
            name="mainOlympia"
            icon={mainicon}
          />
          <ServiceBlock
            title="Admas University Megenagna Campus"
            serviceName="MEGENAGNA CAMPUS"
            name="megenagna"
            icon={Megenagna}
          />
          <ServiceBlock
            title="Admas University Mekanissa Campus"
            serviceName="MEKANISSA CAMPUS"
            name="mekanisa"
            icon={mekanissa}
          />
          <ServiceBlock
            title="Admas University Kality Campus"
            serviceName="KALITY CAMPUS"
            name="kality"
            icon={Kality}
          />
          <ServiceBlock
            title="Admas University Mekelle Campus"
            serviceName="MEKELLE CAMPUS"
            name="mekele"
            icon={mekele}
          />
          <ServiceBlock
            title="Admas University Bisheftu Campus"
            serviceName="BISHEFTU CAMPUS"
            name="bisheftu"
            icon={Bish}
          />
          <ServiceBlock
            title="Admas University Misrak Campus"
            serviceName="MISRAK CAMPUS"
            name="misrak"
            icon={mainicon}
          />
          <ServiceBlock
            title="Admas University Meskel Campus"
            serviceName="MESKEL CAMPUS"
            name="meskel"
            icon={meskel}
          />
          <ServiceBlock
            title="Admas University Distance Studies"
            serviceName="COLLEGE OF DISTANCE EDUCATION"
            name="distance"
            icon={distance}
          />
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;