import React, { useState } from 'react';
import campus from "../../../assets/images/resource/project-10.jpg";
import Mekelebanner from '../../../assets/images/icons/mekele.png';
import Mekele2 from '../../../assets/images/icons/mekele2.jpg';
import Dean from '../../../assets/images/icons/mekelle.jpg';
import MekeleStudent from '../../../assets/images/icons/mekelestudent.jpg';
import accreditationImage from '../../../assets/images/mek_acc1.jpg';
import accreditationImage1 from '../../../assets/images/mek_acc2.jpg';

const Mekele = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
  };

  return (
    <div>
      <div className="banner-slide">
        <img src={Mekelebanner} alt="Banner" className="banner-image" />
        <div className="banner-content">
          <h1>Empowering Students Through a Dedicated and Accomplished Faculty</h1>
          <p>Our faculty are highly qualified and dedicated to helping students reach their full potential.</p>
        </div>
      </div>

      <section className="projects-details">
        <div className="auto-container">
          <div className="sec-title style-two">
            <h2>Admas University Mekele Campus</h2>
          </div>
          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <h4>Message from Campus Dean</h4>
                <div className="text">
                  <p>
                    Welcome to Admas University Mekele Campus. We are committed to providing 
                    <strong> high-quality education, skill-based learning, and career-focused training</strong>. 
                    As a premier private university in Ethiopia, we empower students with the 
                    <strong> knowledge, innovation, and practical experience</strong> needed to excel in today’s competitive world.
                  </p>
                  <p>
                    Our campus offers a vibrant learning environment with 
                    <strong> state-of-the-art facilities, experienced faculty, and industry-aligned programs</strong>. 
                    We focus on equipping students with <em>critical thinking skills, problem-solving abilities, and leadership qualities</em> 
                    that prepare them for real-world challenges.
                  </p>
                  <p>
                    Whether you are a new student, a professional looking to upskill, or an industry leader seeking collaboration, 
                    <strong> Admas University Mekele Campus</strong> provides the ideal platform for academic and career growth. 
                    Join us and be part of a university that is redefining excellence in higher education.
                  </p>
                  <h4>📍 Admas University Mekele Campus – Your Path to Success!</h4>
                </div>
              </div>
              <div className="col-lg-6">
                <figure className="image pht">
                  <img src={Dean} alt="Dean" />
                  <figcaption>Yemane Reda (MA)</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h2>Accredited Programs</h2>
                </div>
                <h5 className='programs'>Postgraduate Programs</h5>
                <ul className="list">
                  {['Accounting & Finance', 'Master of Business Administration', 'Project Management'].map((program, index) => (
                    <li key={index}>
                      <div className="icon-box">
                        <span className="flaticon-book" style={iconStyle}></span>
                        {program}
                      </div>
                    </li>
                  ))}
                </ul>

                <h5 className='programs'>Undergraduate Programs</h5>
                <ul className="list">
                  {['Accounting & Finance', 'Business Management', 'Marketing Management', 'Computer Science'].map((program, index) => (
                    <li key={index}>
                      <div className="icon-box">
                        <span className="flaticon-book" style={iconStyle}></span>
                        {program}
                      </div>
                    </li>
                  ))}
                </ul>

                <h5 className='programs'>TVET Programs</h5>
                <ul className="list">
                  {['Web Development & Database Administration Level 1-4', 'Hardware & Networking Servicing Level 1-4', 
                    'Secretarial Science & Office Administration Level 2-4', 'Marketing & Sales Management Level 2-4', 
                    'Food & Beverage Service Level 1-4', 'Housekeeping & Laundry Service Level 1-4', 
                    'Accounting & Finance Level 2-4', 'Tour Operation Level 2-4', 'Tour Guiding Level 2-4'].map((program, index) => (
                    <li key={index}>
                      <div className="icon-box">
                        <span className="flaticon-book" style={iconStyle}></span>
                        {program}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-6">
                <div className="image"><img src={Mekele2} alt="Mekele Campus" /></div>
                <div className="accreditation">
                  <h5>Certificate of Accreditation</h5>
                  <img 
                    src={accreditationImage} 
                    alt="Accreditation Letter 1" 
                    style={accreditationImageStyle} 
                    onClick={() => openModal(accreditationImage)} 
                  />
                  <img 
                    src={accreditationImage1} 
                    alt="Accreditation Letter 2" 
                    style={accreditationImageStyle} 
                    onClick={() => openModal(accreditationImage1)} 
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-block-two">
            <div className="row">
              <div className="col-lg-6">
                <h4>Student Life @ Admas University Mekele Campus</h4>
                <div className="text">
                  At Admas University Mekele Campus, students enjoy a vibrant academic and social environment. The campus offers various extracurricular activities, clubs, and organizations that foster personal growth and community engagement. Students are encouraged to participate in events that promote cultural exchange, leadership development, and social responsibility, ensuring a well-rounded educational experience.
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image"><img src={MekeleStudent} alt="Students at Mekele Campus" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Full Image */}
      {isModalOpen && (
        <div className="modal" style={modalStyle}>
          <span style={closeButtonStyle} onClick={closeModal}>&times;</span>
          <div style={modalContentStyle}>
            <img src={currentImage} alt="Accreditation Letter" style={imageStyle} />
          </div>
        </div>
      )}
    </div>
  );
};

// Styles
const iconStyle = {
  marginRight: '20px',
  color: '#1ab5f1',
  fontWeight: 'bolder',
  fontSize: '1.5em',
};

const accreditationImageStyle = {
  width: '150px',
  cursor: 'pointer',
  marginRight: '10px',
};

// Simple modal styles
const modalStyle = {
  display: 'flex',
  position: 'fixed',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  maxWidth: '90%',
  maxHeight: '80%',
  overflowY: 'auto',
};

const imageStyle = {
  width: '100%',
  height: 'auto',
  maxHeight: '100%',
};

const closeButtonStyle = {
  color: 'white',
  position: 'absolute',
  top: '20px',
  right: '40px',
  fontSize: '40px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

export default Mekele;