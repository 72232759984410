import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import ScrollToTopButton from '../pages/ScrollToTopButton';

const Distance = () => {
  return (
    <div>
      <div className='distance'>
        <div className="sidebar-page-container">
          <div className="auto-container">
            <div className="row">
              <div className="content-side col-xl-9 col-lg-8 order-lg-2">
                <div className="services-single">
                  <div className="inner-box">
                    <div className="big-image">
                      <img src="assets/images/resource/service-9.jpg" alt="" />
                    </div>
                    <div className='sec-title'>
                      <h2 style={{ textAlign: 'center' }}>College of Distance Education</h2>
                    </div>
                    <div className="text">
                      <p>
                        Admas University, through its College of Distance Education, is committed to addressing the evolving educational needs of society by offering flexible and accessible learning opportunities. To meet the rising demand for highly qualified professionals, the university provides postgraduate programs in Business Management, Agricultural Economics, Educational Planning and Management, Marketing Management, Economics, Rural Development & Agricultural Extension, and Accounting and Finance across the country.
                      </p>
                      <p>
                        Over the years, the university has contributed to the development of skilled and ethically grounded graduates who hold key positions in the civil service and private sectors. Recognizing their need for further academic advancement, Admas University has established a system that delivers affordable, relevant, and high-quality distance education, ensuring students can achieve their academic and professional aspirations without geographical limitations.
                      </p>
                    </div>

                    <div className="facts">
                      <h3 style={{ textAlign: 'center' }}>Admission Criteria</h3>
                      <div className="text">
                        To be eligible for admission to our university, applicants must meet the following criteria:
                      </div>
                    </div>

                    <div className="featured-blocks">
                      <div className="row">
                        <div className="featured-block col-md-8">
                          <div className="featured-inner">
                            <div className="content">
                              <div className="icon-box">
                                <span className="icon flaticon-work-team"></span>
                              </div>
                              <ul>
                                <li>A successful completion of the two-year preparatory program with the minimum University Entrance Examinations result set for admission by the Ministry of Education for the entry year.</li>
                                <li>Completion of a Diploma program (12+2, 10+3, Level 4) with two years of relevant work experience after graduation, and certification from the Center of Competency (COC), along with passing the University’s entrance examination.</li>
                                <li>A degree program student from other accredited Higher Education Institutions who withdrew for non-academic reasons.</li>
                                <li>A first degree graduate in any field of study from accredited Higher Education Institutions.</li>
                                <li>These minimum requirements can be scaled up when there is high competition for admission or when the Senate of the University wants to adjust the criteria.</li>
                                <li>When there is high competition for admission, the following factors shall be considered for offering limited opportunities:
                                  <ul>
                                    <li>Grade point average at graduation</li>
                                    <li>Additional training after graduation</li>
                                    <li>Service year</li>
                                    <li>Gender</li>
                                    <li>Supporting letter from their employers</li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sidebar-side col-xl-3 col-lg-4">
                <aside className="sidebar">
                  <div className="sidebar-widget sidebar-blog-category">
                    <ul className="blog-cat">
                      <li className="active"><a href="performance-upgrade.html">Admas University Distance Education Campuses</a></li>
                      <li><a href="/dmekanissa">Mekanissa (Main Center)</a></li>
                      <li><a href="/adwa">Adwa</a></li>
                      <li><a href="/assosa">Assosa</a></li>
                      <li><a href="/axum">Axum</a></li>
                      <li><a href="/bahirdar">Bahirdar</a></li>
                      <li><a href="/bishoftu">Bishoftu</a></li>
                      <li><a href="/debrebirhan">Debrebirhan</a></li>
                      <li><a href="/dessie">Dessie</a></li>
                      <li><a href="/harar">Harar</a></li>
                      <li><a href="/hawassa">Hawassa</a></li>
                      <li><a href="/jigjiga">Jigjiga</a></li>
                      <li><a href="/jimma">Jimma</a></li>
                      <li><a href="/logia">Logia</a></li>
                      <li><a href="/mekelle">Mekelle</a></li>
                      <li><a href="/meskel-square">Meskel Square (Addis Ababa)</a></li>
                    </ul>
                  </div>

                  <div className="sidebar-widget contact-info-widget">
                    <div className="sidebar-title style-two">
                      <h2>Contact Us</h2>
                    </div>
                    <div className="inner-box">
                      <ul>
                        <li><span className="icon fas fa-phone"></span>+2510116687671</li>
                        <li>
                          <a href="https://t.me/AdmasAA" target="_blank" rel="noopener noreferrer" className="social-icon">
                            <FontAwesomeIcon icon={faTelegramPlane} /> Join Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </div>
  );
};

export default Distance;